import http from '../http'
import {ADMIN_LOGIN_URL} from '../routes/api'

/**
 * OAuth credentials
 *
 * All informations for the connection with OAuth
 *
 * @param {grant_type} Type for the connection
 * @param {client_id} OAuth Client ID for the different environments
 */

const authBody = {
  grant_type: 'password',
  // Public live server - Docker
  // client_id: process.env.REACT_APP_CLIENT_ID_LIVE,
  // client_secret: process.env.REACT_APP_CLIENT_SECRET_LIVE,
  // Default and Docker server
  // client_id: process.env.REACT_APP_CLIENT_ID_LIVE
  // client_id: process.env.REACT_APP_CLIENT_ID_TEST
  client_id: process.env.REACT_APP_CLIENT_ID_INTEGRATION
}

const authBodyRftk = {
  grant_type: 'refresh_token',
  // Public live server - Docker
  // client_id: process.env.REACT_APP_CLIENT_ID_LIVE,
  // client_secret: process.env.REACT_APP_CLIENT_SECRET_LIVE,
  // Default and Docker server
  // client_id: process.env.REACT_APP_CLIENT_ID_LIVE
  // client_id: process.env.REACT_APP_CLIENT_ID_TEST
  client_id: process.env.REACT_APP_CLIENT_ID_INTEGRATION
}

/**
 * Services for authentification
 *
 * All possible methods to the different API Endpoints for authentification
 */

const authService = {
  /**
   * Login for admins
   *
   * @param {credentials} The login credentials (E-mail and password)
   *
   * @returns {Promise<*>}
   */
  login(credentials) {
    const {username, password} = credentials
    const {grant_type, client_id} = authBody
    //const {grant_type, client_id, client_secret} = authBody
    const formData = new FormData()

    formData.append('username', username)
    formData.append('password', password)
    formData.append('grant_type', grant_type)
    formData.append('client_id', client_id)
    //formData.append('client_secret', client_secret)

    return new Promise((resolve, reject) => {
      http.post(ADMIN_LOGIN_URL, formData)
        .then((res) => {
          resolve(res)
        }, (err) => {
          reject(err)
        })
    })

  },
  /**
   * Login for user / patient
   *
   * @param {credentials} The login credentials (E-mail and password)
   * @param {registrationId} The ID of an user registration
   *
   * @returns {Promise<*>}
   */
  loginUser(credentials, registrationId) {
    const {username, password} = credentials
    const {grant_type, client_id} = authBody
    //const {grant_type, client_id, client_secret} = authBody
    const formData = new FormData()

    formData.append('username', username)
    formData.append('password', password)
    formData.append('grant_type', grant_type)
    formData.append('client_id', client_id)
    //formData.append('client_secret', client_secret)

    return new Promise((resolve, reject) => {
      http.post(`/oauth2/${registrationId}/token`, formData)
        .then((res) => {
          resolve(res)
        }, (err) => {
          reject(err)
        })
    })
  },
  /**
   * keep user loged in (extend session timeout)
   *
   * @param {userRole} The Role of an user registration
   *
   * @returns {Promise<*>}
   */
  tryRefreshToken(userRole) {
    const body = Object
      .entries({...authBodyRftk, refresh_token: localStorage.getItem(process.env.REACT_APP_REFRESHTOKEN_NAME)})
      .map((arr) => arr.join('='))
      .join('&')
    return new Promise((resolve, reject) => {
      http.post(`/oauth2/${userRole}/token`, body)
        .then((res) => {
          resolve(res)
          localStorage.setItem(process.env.REACT_APP_REFRESHTOKEN_NAME, res.data.refresh_token)
          localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, res.data.access_token)
        }, (err) => {
          reject(err)
        })
    })
  },

  /**
   * Impersonate another User
   *
   * @param {userRole} The Role of an user registration
   *
   * @returns {Promise<*>}
   */
  tryImpersonate(userRole, rftk) {
    const body = Object
      .entries({...authBodyRftk, refresh_token: rftk})
      .map((arr) => arr.join('='))
      .join('&')
    return new Promise((resolve, reject) => {
      http.post(`/oauth2/${userRole}/token`, body)
        .then((res) => {
          resolve(res)
          localStorage.setItem(process.env.REACT_APP_REFRESHTOKEN_NAME, res.data.refresh_token)
          localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, res.data.access_token)
          localStorage.setItem(process.env.REACT_APP_IMITATION_FLAG, true)
        }, (err) => {
          reject(err)
        })
    })
  },
  /**
   * Validation for the registration code
   *
   * @param {data} Object with multiple parameters
   * @param {registrationId} The ID of an user registration
   *
   * @returns {Promise<*>}
   */
  async validateRegistrationCode(registrationId, data) {
    try {
      const response = await http.post(`/${registrationId}s/validate-registration-code`, data)
      return response.data
    } catch (err) {
      throw err
    }
  },
  /**
   * Update the details in the registration process
   *
   * @param {data} Object with multiple parameters
   * @param {registrationId} The ID of an user registration
   *
   * @returns {Promise<*>}
   */
  async updateDetails(registrationId, data) {
    try {
      const response = await http.post(`/${registrationId}s/complete-registration`, data)
      return response.data
    } catch (err) {
      throw err
    }
  },
  /**
   * Get all user details (data)
   *
   * @returns {Promise<*>}
   */
  getUserDetails() {
    return new Promise((resolve, reject) => {
      http.get('/organisation/user')
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  /**
   * Get all patient details (data)
   *
   * @returns {Promise<*>}
   */
  getPatientDetails() {
    return new Promise((resolve, reject) => {
      http.get('/patient')
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  /**
   * Get all admin details (data)
   *
   * @returns {Promise<*>}
   */
  getAdminDetails() {
    return new Promise((resolve, reject) => {
      http.get('/admin')
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  /**
   * Reset the password of an user (Admin, Professional, Patient)
   *
   * @param {data} Object with multiple parameters
   * @param {registrationId} The ID of an user registration
   *
   * @returns {Promise<*>}
   */
  passwordReset(user, email) {
    return new Promise((resolve, reject) => {
      http.post(`/${user}s/request-password-reset`, {email})
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  /**
   * Complete and final step - Reset the password of an user (Admin, Professional, Patient)
   *
   * @param {payload} Object with multiple parameters
   *
   * @returns {Promise<*>}
   */
  completeResetPassword(payload) {
    return new Promise((resolve, reject) => {
      http.post(`/complete-password-reset`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  }
}

export default authService
